import { type SvgIconTypeMap } from '@mui/material';
import { type OverridableComponent } from '@mui/material/OverridableComponent';
import { type ComponentClass, type FunctionComponent } from 'react';
import { IconType } from 'react-icons';

import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type OverrideIcon =
  | IconType
  | ComponentClass<unknown>
  | FunctionComponent<unknown>
  | OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;

export interface IGenericCardProps {
  size?: string;
  color?: string;
  image?: string;
  title?: string;
  content?: string;
  dateTime?: string;
  secondary?: string;
  primary?: number | string;
  iconPrimary?: OverrideIcon;
}

export interface IMenuItem {
  id?: string;
  url?: string;
  type?: string;
  title?: string;
  target?: boolean;
  disabled?: boolean;
  external?: boolean;
  action?: () => void;
  breadcrumbs?: boolean;
  children?: IMenuItem[];
  logoUrl?: null | string;
  icon?: string | IGenericCardProps['iconPrimary'];
}

export interface IMenuInitialState {
  openItem?: string[];
  openComponent?: string;
  isSidebarOpen?: boolean;
  componentSidebarOpen?: boolean;
}

export enum GROUP_TITLE {
  BONUSES = 'Bonus Management',
  CAMPAIGNS = 'Campaigns',
  MANUAL_ASSIGNMENTS = 'Manual Assignments',
  REPORTS = 'Reports',
  SETTINGS = 'App Settings',
}

const initialState: IMenuInitialState = {
  isSidebarOpen: false,
  openItem: ['bonus-management'],
};

const menuSlice = createSlice({
  initialState,
  name: 'menu',
  reducers: {
    activeItem(
      state: IMenuInitialState,
      action: PayloadAction<IMenuInitialState>
    ) {
      state.openItem = action.payload.openItem;
    },

    openSidebar(
      state: IMenuInitialState,
      action: PayloadAction<IMenuInitialState>
    ) {
      state.isSidebarOpen = action.payload.isSidebarOpen;
    },
  },
});

export const { activeItem, openSidebar } = menuSlice.actions;

export default menuSlice.reducer;
